@import url('https://fonts.googleapis.com/css2?family=Abel&family=Rajdhani&display=swap');

:root {
    --primary: #28636B;
    --secondary: #ffffff;
    --shadow: #DDDEDF
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Abel', sans-serif;
    min-height: 100vh;
    background: #2c2c2c;
}

.container {
    margin-top: 0;
    padding: 0px;
    margin-left: 0;
    margin-right: 0;
}

.logo {
    width: 100px;
    margin-top: 2px;
    margin-bottom: 1rem;
}

.parent-loading {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    background: rgba(0, 0, 0, 0.27);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--primary);
    border-bottom: 16px solid #1a1a1a;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.content-block {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -109%);
    width: 82%;
    text-align: left;
    font-size: 26px;
    text-shadow: 3px 3px 12px black;
}

.bg-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000063;
    backdrop-filter: blur(3px);
}

header {
    padding: 10px;
    background: #0f0f0f;
    box-shadow: -3px -3px 13px 0 #00000080;
}

.row {
    margin-right: 0 !important;
}

header .col-lg-7 {
    text-align: left;
}

header ul {
    padding: 0;
    margin: 0;
    margin-top: 0px;
    margin-left: 1rem;
}

header ul li {
    display: inline-block;
    margin-right: 0px;
    color: white;
    font-weight: bold;
    font-size: 23px;
}

header .col-lg-3 {
    text-align: right
}

span.question-mark {
    background: #2196f3;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 50%;
    color: white;
}

span.question:hover {
    color: white;
}

input.form-control {
    border-radius: 0;
    height: 50px;
    background: none;
    color: white;
}

.btn.btn-success {
    background: var(--primary);
    border-color: var(--primary);
    font-weight: bold;
    color: white;
    box-shadow: none;
    outline: none;
    margin-top: 1rem;
    border-radius: .5rem;
    height: 45px;
    min-width: 122px;
}

header ul li a {
    padding: 7px;
    display: inline-block;
    border-radius: 3px;
    font-weight: bold;
    font-size: 21px;
    color: var(--primary) !important;
    min-width: 90px;
    cursor: pointer;
    text-align: center;
}

header ul li a:hover {
    transition: all ease 0.5s;
    color: #FFF !important;
    text-decoration: none;
}


.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* margin-right: -15px; */
    margin-left: -15px;
    /* justify-content: space-around !important; */
}

.row_head {
    display: -ms-flexbox;
    display: flex;
    /* -ms-flex-wrap: wrap; */
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    /* justify-content: space-between; */
}

.gravity-right {
    text-align: right;
}

.aside-block h1 {
    color: var(--primary);
    margin-bottom: 13px;
}

.heading {
    color: white;
    margin-bottom: 13px;
    font-size: 30px;
}


.aside-block p {
    color: #c1c0c0;
    font-weight: bold;
}

.container {
    margin: 100px auto;
}

.col-lg-4.left-block {
    background: var(--primary);
    min-height: 100vh;
    padding: 45px;
    color: white;
}

.payment-types {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
}

footer {
    width: 100%;
    background: #0f0f0f;
    color: white;
    text-align: center;
    padding: 40px;
    height: 30vh;
}

.activation-support {
    margin: 40px auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 69%);
    top: 50%;
    width: 100%;
}

.devices {
    text-align: center;
}

.devices img {
    width: 108px;
    margin: 12px auto;
}

.package-text {
    font-size: 30px;
    margin-bottom: 0px;
}

.payment-type-button:hover {
    background: black;
    transition: all ease 0.5s;
}

.payment-type-button {
    background-color: none;
    border: none;
    height: 100px;
    border-radius: 5px;
    outline: none;
    padding: 5px 15px;
    min-width: 100px;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.2s;
    cursor: pointer;
    text-align: center;
}

.payment-type-button.active {
    color: black !important;
    background-color: white !important;
}

#paypal-btn {
    margin-right: 5px;
}

.btn.btn-success {
    background: #0c3a41;
    border-color: var(--primary);
    font-weight: bold;
    color: white;
    outline: none;
    margin-top: 1rem;
}

.banner-cookie {
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    border-top: 1px solid;
    background-color: #fff;
}

.c-block {
    position: absolute;
    z-index: 1;
}

.banner-cookie p {
    font-size: 16px;
    color: black;
    margin-top: 1rem;
}

p.price-not {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 6px;
    color: #F44336;
    font-size: 16px;
}


.btn.btn-success:hover {
    box-shadow: none;
}

.btn.btn-success:active {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    font-weight: bold;
    outline-color: #3d3b39 !important;
    box-shadow: 3px 4px 7px 0 #ff572282;
}

a.bottom-link {
    bottom: 10px;
    width: 100%;
    display: inline-block;
    left: 0;
    color: #CCC
}

.help {
    text-align: center;
}

.bold-appname {
    color: var(--primary);
    font-weight: bold;
}

.mini {
    display: inline-block;
    width: 114px;
    margin-right: 4px;
    text-align: center;
}

label {
    display: block;
}

.inline-select {
    display: inline-block;
    width: 130px;
}

.cvv-holder {
    text-align: right;
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.row.device-item {
    background: #efefef;
    margin-bottom: 12px;
    padding: 18px;
    border-radius: 10px;
    transition: all ease 0.6s;
    margin-top: 1rem;
}

.open-block i {
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    cursor: pointer;
}

.open-block i:hover {
    color: var(--primary);
}

.more-playlists {
    transition: all ease 0.6s;
    margin-top: 30px;
}

.row.playlist-item {
    background: white;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 14px;
    height: auto;
    margin-top: 1rem;
}

.action-btns {
    text-align: right;
}

.action-btns i {
    display: inline-block;
    margin-right: 10px;
    font-size: 21px;
    color: #CCC;
    cursor: pointer;
}

.action-btns i:hover {
    color: var(--primary);
    transition: all ease 0.5s;
}

.types {
    cursor: pointer;
}

.types:hover {
    cursor: pointer;
    background: #ce5503;
}

.active-type {
    background: var(--primary);
    color: white;
}

.type ul li {
    display: inline-block;
    width: 100px;
    text-align: center;
    border: 1px solid gray;
    font-weight: bold;
}

.type ul {
    padding-left: 0;
    margin-left: 0;
    margin-top: 60px;
}

.category-item {
    background: white;
    margin-bottom: 5px;
    padding: 7px;
    border-radius: 5px;
    transition: all ease 0.6s;
    cursor: pointer;
}

.category-item:hover {
    color: white;
    background: #d95802;
}

.active {
    background: var(--primary) !important;
    color: white;
}

.channel-item {
    /* background: #5f5f5f; */
    padding: 5px;
    border-radius: 5px;
    margin-top: 9px;
    color: #000000;
    font-weight: bold;
    border: 1px solid gray;
}

.channel-item img {
    width: 50px;
    height: 50px;
}

.channel-item .action-btns {
    margin-top: 10px;
}

.error-message {
    color: red;
}

.popup {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 333;
}

.popup-content {
    width: 40%;
    background: #28636b;
    color: white;
    min-height: 300px;
    padding: 40px;
    border-radius: 5px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 1px 20px 8px #504c4c2e;
    z-index: 3333;
}

.popup-title {
    max-width: 90%;
    font-size: 2.5rem;
    text-align: center;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
}



.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rightInputBtn {
    /* position: absolute; */
    /* left: 0; */
    /* bottom: 17px; */
}

p.country_item {
    text-align: left;
    background: #f9f9f9;
    padding: 7px;
    margin: 0;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
}

p.country_item:hover {
    background: var(--primary);
    color: white;
}

.PhoneInputInput {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

p.separator:after {
    content: "";
    position: absolute;
    width: 43%;
    height: 1px;
    background: gray;
    transform: translate(4%, -50%);
    top: 50%;
}

p.separator:before {
    content: "";
    position: absolute;
    width: 43%;
    height: 1px;
    background: gray;
    transform: translate(-105%, -50%);
    top: 50%;
}

p.separator {
    width: 100%;
    text-align: center;
    position: relative;
}

.bottom-form-block {
    text-align: center;
}

/* language */


.custom-select_main {
    user-select: none;
    position: relative;
    padding: 0 1rem;
}

.selected_language {
    border: 1px solid rgb(167, 160, 160);
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-radius: 3px;
    margin-top: 8px;
    z-index: 5;
    margin: .5rem 0 0;
    cursor: pointer;
}

.selected_language img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.selected_language p {
    color: rgb(255 255 255);
    font-size: 18px;
    margin: 0;
    padding-left: 10px;
    font-weight: 400;
}

.list_language {
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: white;
    z-index: 5;
}

.list_language.opened {
    height: auto;
    border-color: rgb(167, 160, 160);
    z-index: 100;
}

.item_language {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px 10px;
}


.item_language img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.item_language:hover {
    /* box-shadow: 0 0 10px inset black; */
    background-color: rgba(0, 0, 0, 0.1);
}

.item_language p {
    color: rgb(41, 41, 41);
    font-size: 18px;
    margin: 0;
    padding-left: 10px;
    font-weight: 400;
}


.select-outside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    background-color: white;
    opacity: 0;
    z-index: 99;
}

.tab_contsiner {
    width: 50%;
    margin: 0 auto 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    height: 4rem;
    flex-wrap: wrap;
}

.tab_buttons {
    font-size: 1rem;
    background: none !important;
    box-sizing: border-box;
    padding: 3px 8px;
    border: none;
    color: white !important;
    cursor: pointer;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
}

.tab_buttons:hover {
    background-color: transparent;
    color: black;
}

.tab_buttons_active {
    min-width: 4%;
    font-size: 1rem;
    background: white !important;
    box-sizing: border-box;
    padding: 3px 8px;
    border: none;
    border-radius: 0 !important;
    color: black !important;
    cursor: pointer;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
}

.close {
    font-size: 2rem !important;
    position: absolute;
    background-image: url(img/close.png);
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: 0.3rem -0.1rem;
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right: 1rem;
}

.login_group {
    width: 99%;
    display: flex;
    align-items: center;
}

.form-group-epg {
    width: 100%;
}

.device_formgroup {
    max-width: 100% !important;
    margin: 0 auto !important;
    width: 95% !important;
}

.submit-button_epg {
    margin: 1rem 0;
}

.popup_wrapper {
    margin-top: 1rem;
    text-align: center;
}

@media screen and (max-width: 1130px) {
    .popup-title {
        font-size: 1.5rem;
        text-align: center;
    }

    .popup-content {
        width: 70%;
    }
}

@media screena and (max-width: 768px) {
    .container {
        max-width: 80%;

    }

    .col-md-10 {
        /* flex: 0 100% !important; */
        flex: unset !important;
        /* max-width: 100% !important; */
        max-width: unset !important;
    }
}

@media screen and (max-width: 375px) {
    .popup-title {
        font-size: 1rem;
        /* text-align: center; */
    }

    .popup-content {
        width: 90%;
    }
}

.hid-reset-field {
    display: none;
}

.seperator {
    margin: 0 1rem;
    color: var(--primary);
}


.tab_contsiner {
    width: 50%;
    margin: 0 auto 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    height: 4rem;
    flex-wrap: wrap;
}

.tab_buttons {
    font-size: 1rem;
    background: white;
    box-sizing: border-box;
    padding: 3px 8px;
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
}

.tab_buttons:hover {
    background-color: var(--primary);
    color: #fff;
}

.tab_buttons_active {
    min-width: 4%;
    font-size: 1rem;
    background: var(--primary);
    box-sizing: border-box;
    padding: 3px 8px;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;

}

button.tab-button {
    background: white;
    border: none;
    cursor: pointer;
    padding: 7px;
    outline: none;
    border-radius: 5px;
}

button.tab-button:hover {
    background-color: var(--primary);
    color: white;
    transition: all ease 0.2s;
}

.active-tab {
    background: var(--primary) !important;
    color: white !important;
    font-weight: bold;
}


.tab-body {
    margin-top: 10px;
}

.playlist-editable {
    align-items: flex-end;
    justify-content: space-between;


}

@media screen and (max-width: 771px) {
    .playlist-editable {
        flex-direction: column;
    }

    .playlist-name__field {
        width: 100% !important;
    }
}

.playlist-name__field {
    padding: 10px;
    width: 85%;

}

.add-edit__btn {
    margin-left: auto;
}